<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div v-if="message_type != 'mysdgs'" class="page_header">
    <div class="mv_cloud">
      <div class="right_area">
        <div class="img04">
          <img src="@/assets/front_component/images/assets/back02.png" alt="雲" />
        </div>
      </div>
      <div class="left_area">
        <div class="img01">
          <img src="@/assets/front_component/images/assets/back01.png" alt="雲" />
        </div>
      </div>
    </div>
    <div class="en_ttl en">
      <span>{{ messagetype.title_en }}</span>
    </div>
    <div class="content_wrap">
      <h2>{{ messagetype.title_jp }}</h2>
      <div class="mascot_wrap">
        <img src="@/assets/front_component/images/assets/meguru05.png" alt="めぐる君" />
      </div>
    </div>
  </div>
  <main>
    <div v-if="message_type === 'mysdgs'">
      <SdgsGoalRatio />
    </div>
    <div class="section_all_wrap">
      <div class="content_in_wrap">
        <div id="breadcrumb">
          <ul class="breadcrumb_list">
            <li>
              <router-link :to="{ name: 'Front top' }">ホーム</router-link>
            </li>
            <li v-if="showmessage.type == 'thanks'">
              <span>{{ messagetype.title_jp }}一覧</span>
            </li>
            <li v-else>
              <router-link :to="{
                name: 'Front archive',
                params: { type: message_type },
                query: {
                  row_count: this.$route.query.row_count
                    ? this.$route.query.row_count
                    : 25,
                },
              }">{{ messagetype.title_jp }}一覧</router-link>
            </li>
            <li v-if="
              showmessage.type == 'challenge' || showmessage.type == 'sdgs'
            ">
              <span> {{ showYear(showmessage.option) }}</span>
            </li>
            <li v-else>
              <span>{{ showmessage.title }}</span>
            </li>
          </ul>
        </div>
        <div class="content">
          <div class="innar">
            <div class="post_header">
              <div class="post_header_icon">
                <img :src="iconPath" :alt="messagetype.title_jp" />
              </div>
              <div class="post_header_day">
                <span class="post_header_day_time">
                  <img src="@/assets/front_component/images/assets/board01.png" alt="時間" />
                  <time class="en" :datetime="formatDate_time(showmessage.published_at)">{{
                    formatDate(showmessage.published_at) }}</time>
                </span>
                <template v-if="isMessageType">
                  <div class="post_header_day_name post_header_day_name_d">
                    <span class="post_header_day_name post_header_day_name_label">
                          {{ showmessage.type == 'daily' ? '宛先' : '承認者' }}
                    </span>
                    <span>&nbsp;:</span>
                    <span>&nbsp;{{ approved_by }}</span>
                    <span class="post_header_day_name_label">投稿者</span>
                    <span>&nbsp;:</span>
                    <span>&nbsp;{{ posted_by }}</span>
                    <template v-if="showmessage.tags && showmessage.type == 'daily'">
                      <span class="post_header_day_name link_ttl post_header_day_name_label">タグ</span>
                      <span>&nbsp;:</span>
                      <span class="post_header_day_name link_ttl">
                        &nbsp;
                        <small class="post_header_day_name" v-for="tag in showmessage.tags" :key="tag.id">{{ tag.name
                        }}</small>
                      </span>
                    </template>
                </div>
                </template>
                <template v-else>
                  <span v-if="showmessage.from_admin && showmessage.type != 'award'" class="post_header_day_name">投稿者：{{
                    showmessage.from_admin }}</span>
                  <span v-if="showmessage.type == 'award'" class="post_header_day_name">投稿者：めぐる君</span>
                  <span v-else class="post_header_day_name">投稿者：
                    {{ from_user.deleted_at ? from_user.last_name +from_user.first_name + ' (削除済み) ' : from_user.last_name + from_user.first_name }}</span>
                  <p class="post_header_day_name" v-if="showmessage.thanks_type && showmessage.type == 'thanks'">
                    タイプ：<span v-for="thanks_type in showmessage.thanks_type" :key="thanks_type.id" class="thanks_type">{{
                      thanks_type.value }}</span>
                  </p>
                </template>
              </div>
            </div>
            <article class="post_area">
              <h1 v-if="
                showmessage.type == 'challenge' || showmessage.type == 'sdgs'
              ">
                {{ showYear(showmessage.option) }}
              </h1>
              <h1 v-else-if="showmessage.type == 'mysdgs'">{{ showmessage.sdgsReportTitle }}</h1>
              <h1 v-else>{{ showmessage.title }}</h1>

              <div v-if="
                showmessage.type == 'challenge' || showmessage.type == 'sdgs'
              " style="min-height: 300px"
                class="word-break">
                <div class="season_wrap">
                  <h3>目標内容１</h3>
                  <div v-html="showmessage.content"></div>
                </div>
                <div class="season_wrap">
                  <h3>目標内容２</h3>
                  <div v-html="showmessage.content_1"></div>
                </div>
                <div class="season_wrap">
                  <h3>目標内容３</h3>
                  <div v-html="showmessage.content_2"></div>
                </div>
              </div>
              <div v-else class="word-break" style="min-height: 300px" v-html="showmessage.content"></div>

              <div v-if="files.length > 0">
                <h3>関係ファイルダウンロード</h3>
                <ul class="doc_list">
                  <li class="doc_wrap" v-for="file in files" :key="file.id">
                    <div class="doc_left">
                      <p>{{ file.display_name }}</p>
                    </div>
                    <div class="doc_right">
                      <div class="btn_norm">
                        <a v-on:click="dlfile(file.storage_file_name)" class="en" target="_blank"
                          :download="file.updaload_name">DL</a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </article>

            <Form v-if="$route.params.type == 'daily'" enctype="multipart/form-data" autocomplete="off" @submit="register"
              v-slot="{ errors }">
              <div class="reply_area" v-if="reply_messages.data != 0">
                <div class="reply_wrap" v-for="(message, index) in reply_messages" :key="message.id"
                  :id="'reply' + (index + 1)">
                  <p class="res_head">
                    <span class="res_num en">{{ index + 1 }}</span><span class="en">.</span>
                    <span class="res_name" v-if="message.create_user">{{ message.create_user.last_name }}
                      {{ message.create_user.first_name }}</span>
                    <span class="res_day_time">
                      <img src="@/assets/front_component/images/assets/board01.png" alt="時間" />
                      <time class="en" :datetime="formatDate_time(message.published_at)">{{
                        formatDate(message.published_at) }}</time>
                    </span>
                  </p>
                  <p v-if="message.reply_com_id" class="p_t10">
                    <a :href="'#reply' + message.reply_com_id">>> {{ message.reply_com_id }}</a>
                  </p>
                  <div class="post_area">
                    <p v-html="htmlText(message.content)"></p>
                  </div>
                  <div class="control_area" v-if="reply_possible">
                    <a href="#reply_textarea" class="reply_button" v-on:click="reply_com(index + 1)">
                      <div class="icon_wrap">
                        <img src="@/assets/front_component/images/assets/reply01.png" alt="返信" class="mouse_out" />
                        <img src="@/assets/front_component/images/assets/reply02.png" alt="返信" class="mouse_over" />
                      </div>
                      <span class="txt">返信</span>
                    </a>
                  </div>
                </div>
              </div>
              <div class="com_area" id="reply_textarea" v-if="reply_possible">
                <div class="com_wrap">
                  <p class="com_name">
                    {{ $User.last_name }} {{ $User.first_name }}
                  </p>
                  <Field name="title" type="hidden" class="form-control" :class="{ 'is-invalid': errors.title }"
                    v-model="create_message.title" />
                  <ErrorMessage class="each_msg" name="title" />
                  <p class="reply_target p_b5">
                    <span>返信先：>> </span><span class="reply_target_num">1</span><span class="remove_reply"
                      v-on:click="reply_com_remove()">×</span>
                  </p>
                  <textarea rows="5" placeholder="コメントを入力してください。" v-model="create_message.content"></textarea>
                  <Field name="content" type="hidden" class="form-control" placeholder="コメントを入力してください。"
                    :class="{ 'is-invalid': errors.content }" v-model="create_message.content" />
                  <Field name="parent_id" type="hidden" class="form-control" :class="{ 'is-invalid': errors.title }"
                    v-model="create_message.parent_id" />
                  <div class="t_r">
                    <div class="btn_norm">
                      <button type="submit" class="btn btn-primary">
                        送信
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
            <div v-if="showmessage.type == 'thanks'">
              <div class="good_wrap">
                <div class="good_button" v-if="showmessage.good_self !== true">
                  <div class="icon_wrap" @click.once="good_post(showmessage.id)">
                      <img
                        src="@/assets/front_component/images/assets/good01.png"
                        alt="いいね"
                        class="mouse_out"
                      />
                      <img
                        src="@/assets/front_component/images/assets/good02.png"
                        alt="いいね"
                        class="mouse_over"
                      />
                  </div>
                  <span class="en good_num" style="padding-bottom: 25px; color: #e6563b; margin-left: -5px;!important" v-if="showmessage.good_num > 0">+{{ showmessage.good_num }}</span>
                </div>
                <div class="good_button active" v-else @click.once="good_delete(showmessage.id)">
                    <div class="icon_wrap_active">
                      <img
                      src="@/assets/front_component/images/assets/good01.png"
                      alt="いいね"
                      class="mouse_over_color"
                      />
                    </div>
                    <span class="en good_num" style="padding-bottom: 25px; color: #e6563b; margin-left: -5px;!important" v-if="showmessage.good_num > 0">+{{ showmessage.good_num }}</span>                  
                  </div>
                <div class="good_button"  v-if="showmessage.good_like_self !== true" >
                  <div class="icon-like-wrap" @click.once="good_like_post(showmessage.id)">
                    <svg class="bi bi-hand-thumbs-up icon-like" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path class="icon-like-path" d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z"/></svg>
                  </div>
                  <span class="en good_num" style="padding-bottom: 25px; color: #3197e0; margin-left: -1px;!important" v-if="showmessage.good_like_num > 0">+{{ showmessage.good_like_num }}</span>
                </div>
                <div class="good_button" v-else>
                  <div class="icon_wrap" style="background-color: #3197e0; border: #3197e0 solid 1px;" @click.once="good_like_delete(showmessage.id)">
                    <svg class="bi bi-hand-thumbs-up icon-like" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path class="icon-like-path" style="fill: #ffffff;" d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z"/></svg>
                  </div>
                  <span class="en good_num" style="padding-bottom: 25px; color: #3197e0; margin-left: -6px;!important" v-if="showmessage.good_like_num > 0">+{{ showmessage.good_like_num }}</span>
                </div>
              </div>
            </div>
            <div v-if="showmessage.type == 'mysdgs'">
              <div class="good_wrap">
                <div class="good_button" v-if="showmessage.good_self !== true">
                  <div class="icon_wrap" @click.once="good_post(showmessage.id)">
                      <img
                        src="@/assets/front_component/images/assets/good01.png"
                        alt="いいね"
                        class="mouse_out"
                      />
                      <img
                        src="@/assets/front_component/images/assets/good02.png"
                        alt="いいね"
                        class="mouse_over"
                      />
                  </div>
                  <span class="en good_num" style="padding-bottom: 25px; color: #e6563b; margin-left: -5px;!important" v-if="showmessage.good_num != 0 && !(showmessage.good_num > 999)">+{{ showmessage.good_num }}</span>
                </div>
                <div class="good_button active" v-else @click.once="good_delete(showmessage.id)">
                    <div class="icon_wrap_active">
                      <img
                      src="@/assets/front_component/images/assets/good01.png"
                      alt="いいね"
                      class="mouse_over_color"
                      />
                    </div>
                    <span class="en good_num" style="padding-bottom: 25px; color: #e6563b; margin-left: -5px;!important" v-if="showmessage.good_num != 0 && !(showmessage.good_num > 999)">+{{ showmessage.good_num }}</span>                  
                  </div>
                <div class="good_button"  v-if="showmessage.good_like_self !== true" >
                  <div class="icon-like-wrap" @click.once="good_like_post(showmessage.id)">
                    <svg class="bi bi-hand-thumbs-up icon-like" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path class="icon-like-path" d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z"/></svg>
                  </div>
                  <span class="en good_num" style="padding-bottom: 25px; color: #3197e0; margin-left: -1px;!important" v-if="showmessage.good_like_num != 0 && !(showmessage.good_like_num > 999)">+{{ showmessage.good_like_num }}</span>
                </div>
                <div class="good_button" v-else>
                  <div class="icon_wrap" style="background-color: #3197e0; border: #3197e0 solid 1px;" @click.once="good_like_delete(showmessage.id)">
                    <svg class="bi bi-hand-thumbs-up icon-like" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path class="icon-like-path" style="fill: #ffffff;" d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z"/></svg>
                  </div>
                  <span class="en good_num" style="padding-bottom: 25px; color: #3197e0; margin-left: -6px;!important" v-if="showmessage.good_like_num != 0 && !(showmessage.good_like_num > 999)">+{{ showmessage.good_like_num }}</span>
                </div>
              </div>
            </div>
            <div class="t_c m_t50">
              <div  class="previous-next-button">
                <div  v-if="currentPage <= 1" class="page-btn-disabled"></div>
                <div v-else class="btn_wide green m_t20">
                  <button type="button" class="page-link" @click="previousPage" >前の投稿へ</button>                 
                    <!-- « 前へ -->
                </div>
                <div v-if="currentPage >= totalPages"  class="page-btn-disabled"></div>
                <div v-else class="btn_wide green m_t20">
                  <!-- 次へ » -->
                  <button type="button" @click="nextPage" class="page-link " > 次の投稿へ</button> 
                </div>
              </div>
              <div class="dotline-previous-next m_t30 m_b70">
                <div>
                  <div class="btn_wide blue m_t50 m_b50">
                    <a class="" style="cursor: pointer" @click="gotoBack()">もどる</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="deco_wrap01">
            <img src="@/assets/front_component/images/assets/bard01.png" alt="青鳥" />
          </div>
          <div class="deco_wrap02">
            <img src="@/assets/front_component/images/assets/bard02.png" alt="緑鳥" />
          </div>
        </div>
      </div>
      <div class="kusa_outline">
        <div class="kusa_area">
          <div class="deco_wrap01">
            <img src="@/assets/front_component/images/assets/kusa01.png" alt="草" />
          </div>
          <div class="deco_wrap02">
            <img src="@/assets/front_component/images/assets/kusa02.png" alt="草" />
          </div>
          <div class="deco_wrap03">
            <img src="@/assets/front_component/images/assets/kusa04.png" alt="草" />
          </div>
          <div class="deco_wrap04">
            <img src="@/assets/front_component/images/assets/kusa03.png" alt="草" />
          </div>
          <div class="deco_wrap05">
            <img src="@/assets/front_component/images/assets/kusa04.png" alt="草" />
          </div>
          <div class="deco_wrap06">
            <img src="@/assets/front_component/images/assets/kusa03.png" alt="草" />
          </div>
          <div class="deco_wrap07">
            <img src="@/assets/front_component/images/assets/kusa05.png" alt="草" />
          </div>
          <div class="deco_wrap08">
            <img src="@/assets/front_component/images/assets/kusa04.png" alt="草" />
          </div>
          <div class="deco_wrap09">
            <img src="@/assets/front_component/images/assets/kusa01.png" alt="草" />
          </div>
          <div class="deco_wrap10">
            <img src="@/assets/front_component/images/assets/kusa07.png" alt="草" />
          </div>
          <div class="deco_wrap11">
            <img src="@/assets/front_component/images/assets/kusa06.png" alt="草" />
          </div>
          <div class="deco_wrap12">
            <img src="@/assets/front_component/images/assets/kusa01.png" alt="草" />
          </div>
          <div class="deco_wrap13">
            <img src="@/assets/front_component/images/assets/kusa04.png" alt="草" />
          </div>
          <div class="deco_wrap14">
            <img src="@/assets/front_component/images/assets/kusa03.png" alt="草" />
          </div>
          <div class="deco_wrap15">
            <img src="@/assets/front_component/images/assets/kusa04.png" alt="草" />
          </div>
          <div class="deco_wrap16">
            <img src="@/assets/front_component/images/assets/kusa03.png" alt="草" />
          </div>
          <div class="deco_wrap17">
            <img src="@/assets/front_component/images/assets/kusa01.png" alt="草" />
          </div>
        </div>
      </div>
      <div class="deco_outline">
        <div class="deco_area">
          <div class="deco_wrap01">
            <img src="@/assets/front_component/images/assets/kikyu01.png" alt="気球" />
          </div>
          <div class="deco_wrap02">
            <img src="@/assets/front_component/images/assets/hana01.png" alt="花" class="hana01" />
            <img src="@/assets/front_component/images/assets/hana02.png" alt="花" class="hana02" />
            <img src="@/assets/front_component/images/assets/hana03.png" alt="花" class="hana03" />
          </div>
          <div class="deco_wrap03">
            <img src="@/assets/front_component/images/assets/hana01.png" alt="花" class="hana01" />
            <img src="@/assets/front_component/images/assets/hana02.png" alt="花" class="hana02" />
            <img src="@/assets/front_component/images/assets/hana03.png" alt="花" class="hana03" />
          </div>
          <div class="deco_wrap04">
            <img src="@/assets/front_component/images/assets/heri01.png" alt="ヘリコプター" />
          </div>
          <div class="deco_wrap05">
            <img src="@/assets/front_component/images/assets/kikyu02.png" alt="気球" />
          </div>
          <div class="deco_wrap06">
            <img src="@/assets/front_component/images/assets/hana01.png" alt="花" class="hana01" />
            <img src="@/assets/front_component/images/assets/hana02.png" alt="花" class="hana02" />
            <img src="@/assets/front_component/images/assets/hana03.png" alt="花" class="hana03" />
          </div>
          <div class="deco_wrap07">
            <img src="@/assets/front_component/images/assets/hana01.png" alt="花" class="hana01" />
            <img src="@/assets/front_component/images/assets/hana02.png" alt="花" class="hana02" />
            <img src="@/assets/front_component/images/assets/hana03.png" alt="花" class="hana03" />
          </div>
          <div class="deco_wrap08">
            <img src="@/assets/front_component/images/assets/kikyu03.png" alt="気球" />
          </div>
        </div>
      </div>
    </div>
  </main>
  <div class="menu_wrap">
    <div class="menu_icon">
      <img src="@/assets/front_component/images/assets/menu01.png" alt="メニュー" />
    </div>
    <div class="menu_list_wrap">
      <div class="menu_ttl">作成する</div>
      <ul class="menu_list">
        <li>
          <router-link
            :to="{
              name: 'Control messagecreate',
              params: { type: 'mysdgs' },
            }"
            >SDGsレポート</router-link
          >
        </li>
        <!-- <li><router-link :to="{ name: 'Control messagecreate', params: { type: 'news' }}">お知らせ</router-link></li> -->
        <li>
          <router-link :to="{ name: 'Control messagecreate', params: { type: 'thanks' } }">サンクス&ホメロンカード</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Control messagecreate', params: { type: 'idea' } }">創発カード</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Control messagecreate', params: { type: 'daily' } }">気づき日報</router-link>
        </li>
        <li>
          <router-link :to="{
            name: 'Control messagecreate',
            params: { type: 'photolog' },
          }">フォトログ</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Control messagecreate', params: { type: 'column' } }">コラム</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Api from "@/apis/Api";
import message from "@/apis/Message";
import user from "@/apis/User";
import dayjs from "dayjs";
import MessageType from "@/const/MessageType.json";
import { Form, Field, ErrorMessage } from "vee-validate";
import Notification from "@/apis/Notification";
import "@/assets/front_component/js/functions.js";
import SdgsGoalRatio from '@/views/front_panel/mypage/sdgs/SdgsGoalRatio';

import $ from "jquery";

export default {
  name: "message",
  data() {
    return {
      //-----dailyのコメント関連-----//
      approved_by: null,
      posted_by: null,
      reply_messages: [],
      create_message: {
        title: "気づき日報の返信コメント",
        target_ids: [],
        from_type: "users",
        from_id: this.$User.id,
        type: "daily_res",
        content_type: "HTML",
        public: "非公開",
        status: "公開",
        parent_id: this.$route.params.id,
      },
      reply_com_id: "",
      reply_possible: true,
      //-----コメント関連終わり-----//

      iconPath: "",
      good_message: {
        title: "掲示板のいいね",
        content: "掲示板のいいね",
        target_ids: [],
        from_type: "users",
        from_id: this.$User.id,
        type: "board_good",
        content_type: "HTML",
        public: "非公開",
        status: "公開",
        parent_id: "",
      },
      good_like_message: {
        title: "掲示板のいいね",
        content: "掲示板のいいね",
        target_ids: [],
        from_type: "users",
        from_id: this.$User.id,
        type: "thumps_like",
        content_type: "HTML",
        public: "非公開",
        status: "公開",
        parent_id: "",
      },
      showmessage: {},
      from_user: {},
      files: {},
      MessageType() {
        return MessageType;
      },
      loader: false,
      message,
      messagesdata:'',
      totalItems: 0, // Total count of items
      totalPages: 0, // Calculate total pages
      currentPage:0,
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    SdgsGoalRatio
  },
  computed: {
    isMessageType() {
      if (this.showmessage.type == 'column' || this.showmessage.type == 'idea' || this.showmessage.type == 'photolog' || this.showmessage.type == 'daily' || this.showmessage.type == 'sdgs' || this.showmessage.type == 'challenge') {
        return true;
      } else {
        return false;
      }
    }
  },
  beforeCreate() {
    $(function () {
      // body に open クラスをつけたりはずしたりする( open クラスは空)
      $(".g_header_list").removeClass("open");
      $("#button").removeClass("active");
      $(document.body).removeClass("open");
      var result = $("body").attr("style");
      var int_data = result.replace(/[^0-9]/g, "");
      $("html,body").css({
        overflow: "auto",
        height: "auto",
      });
      //bodyfixedを解除する
      $("body").css({
        position: "",
        width: "",
        top: "",
      });
      $(window).scrollTop(int_data);
      $(".g_header_list  > li.has_child").removeClass("open");
      $(".g_header_list  > li.has_child").find(".menu_outwrap").slideUp();
    });
    $(function () {
      $("#js-loader").show();
      $(window).scrollTop(0);
    });
  },
  created() {
    console.log("call message");
    this.message_type = this.$route.params.type;
    this.message_id = this.$route.params.id;
    // this.showmessageViewPopup(this.message_id);
    this.messagetype = MessageType[this.message_type];
    this.iconPath = require("@/assets/front_component/images/assets/" +
      this.messagetype.imgPath);
    this.getFile();
    if (this.message_type == "daily") {
      this.replyList(this.message_id);
    }
    this.getmessage(this.message_type,1,this.message_id);
  },
  methods: {
    setReadAt(message) {      
      Notification.getNotificationByMessageId(message.id)
        .then(
          (response) => {
            if (response != null) {
              Notification.notificationRead(response.data.data.id).then((response) => {
                if (response != null) {
                  this.$router.push({
                    name: "Front show",
                    params: { type: message.type, id: message.id },
                  });
                }
              });
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    getmessage(type, page, requestId) {  
      $("#js-loader").show();
      var ua = navigator.userAgent;
      if (ua.indexOf('iPhone') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0){
        $(window).scrollTop(100);
      } else {
        $(window).scrollTop(200);
      }
      let users = null;
      let getMessageType  = '';
      let localGetType  = '';
      let localGetUsers = '';
      if (type == 'column' || type == 'daily' ||  type == 'thanks' || type == 'award') {    
          getMessageType = localStorage.getItem("messageType");
          localGetUsers =  localStorage.getItem("messagewithUsers");
          localGetType = getMessageType;
          if ( (localGetType == 'user-profile' && type == 'daily' || type == 'column' || type == 'thanks') || (localGetType == 'target-message' && type == 'award' || type == 'thanks')) {
                localGetType  = type;
          }        
          if (localGetType == type) {
            if (localGetUsers && localGetUsers != 'null') {
                users = localGetUsers ;          
            }
          }
        }
      if (getMessageType == 'target-message' ) {      
        message
        // .myTargetMessages(type, users, 1,null)
        .myTargetMessages(type, users, 1,page)
          .then(
            (response) => {
              this.messageValueAssign(response, requestId,page)           
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
                // $("#js-loader").hide();
            });
      } else {             
        message
        // messagesList(type, users=null, page = 25, order=null, status = null, pagenumber = null)
          .messagesList(type, users, 1,null,null,page)
          .then(
            (response) => {
              this.messageValueAssign(response, requestId,page)            
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
                // $("#js-loader").hide();
            });
      }
    },
    messageValueAssign(response,requestId,page) {
      this.messages = response.data.data[0];
      this.messagesdata = response.data;
      this.totalItems = response.data.total; // Total number of items            
      this.currentPage = page; // Current page
      this.totalPages = this.totalItems;
      let getResponsceMessageId = this.messages.id;
      if (requestId) {
        if (getResponsceMessageId == requestId) {            
          let getMessageType = localStorage.getItem("messageType");
          if (getMessageType == 'target-message' && this.messages.read_at == null && (this.message_type == 'award' || this.message_type == 'thanks')) {                
            this.setReadAt(this.messages);
          }
          this.showmessageViewPopup(getResponsceMessageId);
        } else {
          this.getmessage(this.message_type ,this.currentPage + 1,requestId);
        }              
      } else {
        let getMessageType = localStorage.getItem("messageType");
        if (getMessageType == 'target-message' && this.messages.read_at == null && (this.message_type == 'award' || this.message_type == 'thanks')) {
            this.setReadAt(this.messages);
        }
        this.showmessageViewPopup(getResponsceMessageId);              
      }
    },
    previousPage(){
      this.approved_by = null;
      this.posted_by = null;
      this.showmessage = {};
      this.from_user = {};
      this.files = {};
      if (this.messagesdata.prev_page_url) {      
      this.getmessage(this.message_type , this.currentPage - 1);
      }
    },
    nextPage(){
      this.approved_by = null;
      this.posted_by = null;
      this.showmessage = {};
      this.from_user = {};
      this.files = {};
      if (this.messagesdata.next_page_url) {        
        this.getmessage(this.message_type ,this.currentPage + 1);
      } 
    },
    register() {
      message
        .register(this.create_message)
        .then(
          (response) => {
            if (this.reply_com_id) {
              this.postMessageMeta(
                "reply_com",
                this.reply_com_id,
                "返信コメントへの返信",
                response.data.data.id
              );
            }
            this.$router.push({
              name: "Front show",
              params: { type: "daily", id: this.message_id },
            });
            location.reload(true);
          },
          (error) => {
            console.log(error);
            // this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => { });
    },
    postMessageMeta(key, value, description, id) {
      var post_json = {
        key: key,
        value: value,
        description: description,
      };
      message
        .register_meta(post_json, id)
        .then(
          (response) => {
            console.log("meta");
          },
          (error) => {
            console.log("metaerror");
            console.log(error);
            // this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log("metaerrorcatch");
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => { });
    },
    reply_com(id) {
      this.reply_com_id = "";
      this.reply_com_id = id;
      console.log(this.reply_com_id);
      $(".reply_target").show();
      $(".reply_target_num").text(this.reply_com_id);
    },
    reply_com_remove() {
      this.reply_com_id = "";
      console.log(this.reply_com_id);
      $(".reply_target").hide();
      $(".reply_target_num").text("");
    },
    replyList(id) {
      this.loader = true;
      message
        .listChild(id, "daily_res")
        .then(
          (response) => {
            if (response != null) {
              this.reply_messages = response.data.data;

              this.reply_messages.forEach((ob, i) => {
                user
                  .show(ob.act_users[0].user_id) //投稿者の取得
                  .then((response) => {
                    if (response != null) {
                      this.reply_messages[i].create_user = response.data.data;
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  })
                  .finally(() => { });

                message
                  .getMeta(ob.id) //metaの取得
                  .then((response) => {
                    if (response != null) {
                      if (response.data.data[0]) {
                        this.reply_messages[i].reply_com_id =
                          response.data.data[0].value;
                        console.log(response.data.data[0].value);
                      }
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  })
                  .finally(() => { });
              });

              console.log(this.reply_messages);
            }
          },
          (error) => {
            console.log(error);
            console.log(error.response.data.errors);
            // this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    htmlText(msg) {
      return msg.replace(/\r?\n/g, "<br>");
    },
    formatDate: (dateStr) => dayjs(dateStr).format("YYYY.MM.DD"),
    formatDate_time: (dateStr) => dayjs(dateStr).format("YYYY-MM-DD"),
    showmessageViewPopup(id) {
      // showmessage
      // this.loader = true;
      message
        .show(id)
        .then(
          (response) => {
            if (response != null) {
             
              if (
                response.data.data.status != "公開" &&
                response.data.data.status != "承認済" &&
                this.message_type !== "challenge" &&
                this.message_type !== "sdgs"
              ) {
                this.$router.push({ name: "Front 404" });
              }

              this.showmessage = response.data.data;
              // <!--OTHER-4884 : 表示不整合箇所の修正対応  -->
              // this.posted_by = this.showmessage.created_by.last_name + ' ' + this.showmessage.created_by.first_name;                      
              this.posted_by = this.showmessage.deleted_created_by ?  this.showmessage.deleted_created_by.last_name + ' ' + this.showmessage.deleted_created_by.first_name   + ' (削除済み) ' :
                            this.showmessage.created_by.last_name + ' ' + this.showmessage.created_by.first_name ;

              if (response.data.approver_data != null && response.data.approver_data != undefined) {
                // <!--OTHER-4884 : 表示不整合箇所の修正対応  -->
                // this.approved_by = response.data.approver_data.user_name;
                this.approved_by = response.data.approver_data.deleted_at ? response.data.approver_data.user_name + ' (削除済み) ' :response.data.approver_data.user_name;
              } else {
                this.approved_by = '-- --';
              }

              // for aprovel user in column and photolog screen
              this.showmessage.from_user = "";

              //this.showmessage.act_users.forEach((user) => {
              this.showmessage.act_users.forEach((user) => {
                if (user.act_type == "作成") {
                  this.getFromUser(user.user_id);
                }
              });

              //Jquery
              // youtubeがタグの構成上表示出来ないので以下でタグを変換している
              $(function () {
                $("figure.media").each(function () {
                  var $y_url = $(this)
                    .children()
                    .attr("url")
                    .replace("https://youtu.be/", "");
                  $y_url = $(this)
                    .children()
                    .attr("url")
                    .replace("https://www.youtube.com/watch?v=", "");
                  $(this)
                    .children()
                    .replaceWith(
                      "<iframe class='post_youtube' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
                    );
                  console.log($y_url);
                  $(this)
                    .children(".post_youtube")
                    .attr("src", "https://www.youtube.com/embed/" + $y_url);
                });
              });

              // created users only need to show the list
              // if (this.showmessage.act_users[0]["user_id"] != null) {
              //   this.getFromUser(this.showmessage.act_users[0]["user_id"]);
              // }

              // MFUX-1323 気づき日報はログインユーザと同じ部署の人はだれでもコメント投稿を可能にする

              // if (this.showmessage.act_users[0]["user_id"] == this.$User.id) {
              //   this.reply_possible = true;
              // }
              // this.showmessage.target_ids.forEach((ob) => {
              //   if (ob == this.$User.id) {
              //     this.reply_possible = true;
              //   }
              // });

              if (this.message_type == "mysdgs") {
                let date = this.showmessage.published_at;
                let publishedDate = date.split('T')[0].split('-');
                let createdBy = this.showmessage.created_by ? this.showmessage.created_by.last_name :this.showmessage.deleted_created_by.last_name;
                let sdgsReportTitle = `${publishedDate[0]}年${publishedDate[1]}月${publishedDate[2]}日 ${createdBy}さんの活動`;
                this.showmessage.sdgsReportTitle = sdgsReportTitle;
              }

              if (
                this.message_type == "challenge" ||
                this.message_type == "sdgs" ||
                this.message_type == "mysdgs"
              ) {
                this.showmessage.option = "";
                var contentSplit = JSON.parse(this.showmessage.content);
                var TermoOption = contentSplit;
                this.showmessage.option = TermoOption["option"];
                this.showmessage.content = TermoOption["content"];
                this.showmessage.content_1 = TermoOption["content_1"];
                this.showmessage.content_2 = TermoOption["content_2"];
              }
              if (this.message_type == "thanks") {
                message
                  .showmetakey(id, "key", "thanks_type")
                  .then((response) => {
                    if (response != null) {
                      this.goodList(id);
                      this.goodLikeList(id)
                      this.showmessage.thanks_type = response.data.data;
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  })
                  .finally(() => { });
              }
              if (this.message_type == "mysdgs") {
                message
                  .showmetakey(id, "key", "mysdgs_type")
                  .then((response) => {
                    if (response != null) {
                      this.goodList(id);
                      this.goodLikeList(id)
                      this.showmessage.thanks_type = response.data.data;
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  })
                  .finally(() => { });
              }
              message
                .showmetakey(id, "key", "from_admin")
                .then((response) => {
                  if (response != null) {
                    this.showmessage.from_admin = response.data.data[0].value;
                  }
                })
                .catch((error) => {
                  console.log(error);
                })
                .finally(() => { });
              console.log(this.showmessage);

              if(this.message_type == 'daily'){
                // <!--OTHER-4884 : 表示不整合箇所の修正対応  -->
                // let target_user_name = this.showmessage.target_users[0].last_name + ' ' + this.showmessage.target_users[0].first_name
                // this.approved_by = target_user_name;
                let target_user_name = this.showmessage.deleted_target_users ? 
                                      this.showmessage.target_users[0].last_name + ' ' + this.showmessage.target_users[0].first_name + '(削除済み)':
                                      this.showmessage.target_users[0].last_name + ' ' + this.showmessage.target_users[0].first_name ;
                this.approved_by = target_user_name;
              }
            }
            $("#js-loader").hide();
            $("#js-loader").delay(300).fadeOut(600);
          },
          (error) => {
            console.log(error);
            console.log(error.response.data.errors);
            // this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          $("#js-loader").hide();
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {
           $("#js-loader").hide();
          this.loader = false;
        });
    },
    getFromUser(user_id) {
      user
        .show(user_id)
        .then((response) => {
          if (response != null) {
            this.from_user = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => { });
    },
    getFile() {
      message
        .getFile(this.$route.params.id)
        .then(
          (response) => {
            if (response != "") {
              this.files = response.data.data;
              console.log(this.files);
            }
          },
          (error) => {
            console.log(error);
            console.log(error.response.data.errors);
            // this.$router.push({ name: "Front Error" });
          }
        )
        .catch(() => {
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => { });
    },
    dlfile(path) {
      this.windowReference = window.open();
      //let fileApiPass = '/api/v1/file/adedd8819fecb6f2abd2756a7771803d?attachment';
      let fileApiPass = "/api/v1/file/" + path;
      console.log(fileApiPass);
      Api()
        .get(fileApiPass, { responseType: "arraybuffer" })
        .then((response) => {
          //console.log(response.headers['content-filename']);
          //console.log(response.headers['content-type']);
          let blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          /**
           * 新規タブ
           */
          this.windowReference.location = window.URL.createObjectURL(blob);
          /**
           * 疑似ダウンロード
           */
          //let link= document.createElement('a')
          //link.href= window.URL.createObjectURL(blob)
          //link.download= response.headers['content-filename']
          // // 以下コメントアウトで実行
          // link.click()
        });
    },
    async goodList(id) {
      this.loader = true;
      message
        .listChild(id, "board_good")
        .then(
          (response) => {
            if (response != null) {
              this.showmessage.good_num = response.data.data.length;
              response.data.data.forEach((ob, i) => {
                if (ob.act_users[0].user_id == this.$User.id) {
                  this.showmessage.good_self = true;
                }
              });
            }
          },
          (error) => {
            console.log(error);
            this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    async goodLikeList(id) {
      this.loader = true;
      message
        .listChild(id, "thumps_like")
        .then(
          (response) => {
            if (response != null) {

              this.showmessage.good_like_num = response.data.data.length;
              console.log("Show message", response)
              response.data.data.forEach((ob, i) => {
                if (ob.act_users[0].user_id == this.$User.id) {
                  this.showmessage.good_like_self = true;
                }
              });
            }
          },
          (error) => {
            console.log(error);
            this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    good_post(id) {
      
      this.good_message.parent_id = id;
      message
        .register(this.good_message)
        .then(
          (response) => {
            console.log("Response-->", this.showmessage)
            this.showmessage.good_num = this.showmessage.good_num + 1;
            this.showmessage.good_self = true;            
          })
    },
    good_like_post(id) {
      
      this.good_like_message.parent_id = id;
      message
        .register(this.good_like_message)
        .then(
          (response) => {
            this.showmessage.good_like_num = this.showmessage.good_like_num + 1;
            this.showmessage.good_like_self = true;          
          })
    },
    good_delete(id) {
        message
          .listChild(id, "board_good")
          .then(
            (response) => {
              if (response != null) {
                response.data.data.forEach((ob, i) => {
                  if (ob.act_users[0].user_id == this.$User.id) {
                    message
                      .delete(ob.id)
                      .then((response) => {
                        if (response != null) {
                          this.showmessage.good_num =
                            this.showmessage.good_num - 1;
                          this.showmessage.good_self = false;
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      })
                      .finally(() => {
                        this.loader = false;
                      });
                  }
                });
              }
            },
            (error) => {
              console.log(error);
              this.$router.push({ name: "Front Error" });
            }
          )
          .catch((error) => {
            console.log(error);
            this.$router.push({ name: "Front Error Catch" });
          })
          .finally(() => {
            this.loader = false;
          });

    },
    good_like_delete(id) {
        message
          .listChild(id, "thumps_like")
          .then(
            (response) => {
              if (response != null) {
                response.data.data.forEach((ob, i) => {
                  if (ob.act_users[0].user_id == this.$User.id) {
                    message
                      .delete(ob.id)
                      .then((response) => {
                        if (response != null) {
                          this.showmessage.good_like_num =
                            this.showmessage.good_like_num - 1;
                          this.showmessage.good_like_self = false;
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      })
                      .finally(() => {
                        this.loader = false;
                      });
                  }
                });
              }
            },
            (error) => {
              console.log(error);
              this.$router.push({ name: "Front Error" });
            }
          )
          .catch((error) => {
            console.log(error);
            this.$router.push({ name: "Front Error Catch" });
          })
          .finally(() => {
            this.loader = false;
          });

    },
    showYear(year) {
      if (typeof year == "number") {
        year = year.toString();
      }

      let setYear = "";
      switch (year) {
        case "2022":
          setYear = "49期 (2022)";
          break;
        case "2023":
          setYear = "50期 (2023)";
          break;
        case "2024":
          setYear = "51期 (2024)";
          break;
        case "2025":
          setYear = "52期 (2025)";
          break;
        case "2026":
          setYear = "53期 (2026)";
          break;
        default:
          setYear = year;
          break;
      }

      return setYear;
    },
    gotoBack() {
      if (window.history.state.back == "/") {
        this.$router.push({
          name: "Front top",
        });
      } else if (this.message_type == "thanks") {
        this.$router.push({
          name: "Front user edit",
          hash: "#target_area",
        });
      } else if (this.message_type == "column") {
        this.$router.push({
          name: "Front archive",
          params: { type: this.message_type },
          query: {
            row_count: this.$route.query.row_count
              ? this.$route.query.row_count
              : 25,
            column_type: this.$route.query.column_type
              ? this.$route.query.column_type
              : 'all',
          },
        });
      } else {
        this.$router.push({
          name: "Front archive",
          params: { type: this.message_type },
          query: {
            row_count: this.$route.query.row_count
              ? this.$route.query.row_count
              : 25,
          },
        });
      }
    },
  },
  mounted() {

    document.body.className = "page_template page_sdgs";
    this.$nextTick(function () {
      $(function () {
        $(".menu_icon").click(function () {
          if ($(".menu_list_wrap").hasClass("show")) {
            $(".menu_list_wrap").removeClass("show");
          } else {
            $(".menu_list_wrap").addClass("show");
          }
        });
      });
      $(document).click(function (event) {
        if (!$(event.target).closest(".menu_wrap").length) {
          $(".menu_list_wrap").removeClass("show");
        }
      });
      $(function () {
        var content_height = $("#app").height(); // コンテンツの高さを取得
        var pagetop_show = content_height - 1500; // ページトップを出すの高さを設定
        $(window).on("scroll", function () {
          var scroll = $(window).scrollTop();
          var windowHeight = $(window).height();
          //ページトップ表示
          if (scroll > pagetop_show && scroll > 60) {
            $(".pagetop_wrap").addClass("show");
          } else {
            $(".pagetop_wrap").removeClass("show");
          }
        });
      });
      $(function () {
        $("#js-loader").show();
        // $("#js-loader").delay(300).fadeOut(600);
        // $(window).scrollTop(0);
      });
      $(function () {
        var ua = navigator.userAgent;
        $(document).on(
          "click",
          'a[href^="#"]:not(".pop-modal"):not(".news-modal")',
          function () {
            var speed = 550;
            var href = $(this).attr("href");
            var target = $(href == "#" || href == "" ? "html" : href);

            if (
              ua.indexOf("iPhone") > 0 ||
              (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0)
            ) {
              var position = target.offset().top - 0; //ヘッダの高さ分位置をずらす
            } else {
              position = target.offset().top - 110; //ヘッダの高さ分位置をずらす
            }

            $("html, body").animate({ scrollTop: position }, speed, "swing");
            return false;
          }
        );
      });
    });
  },
  updated() {
    console.log("アップデート");
  },
};
</script>

<style scoped>
 
/* 外部CSSスタイルシートを追加する */
@import "../../../assets/front_component/scss/_sample_thanks_like_Button.scss";

.icon-like-wrap{
  border-radius: 50%;
  height: 31px;
  width: 31px;
  border: #3197e0 solid 1px;
  position: relative;
  cursor: pointer;
}
.word-break {
  word-break: break-all;
}
.icon-like-wrap:hover{
  background-color: #3197e0;
  cursor: pointer;
}
.icon-like-wrap:hover svg path{
  fill: #ffffff;
}

.icon-like{
  height: 22px; 
  width: 22px; 
  position: absolute;
  cursor: pointer;
  top: 3px;
  left: 4px;
}
.icon-like-path {
  stroke: none; 
  fill: #3197e0;
  transition: none;
}
.page-btn-disabled {
  visibility: hidden;
}

/* もとる  */
.dotline-previous-next {
  width: 100%;
  height: 2px;
  background-image: url(~@/assets/front_component/images/assets/doc02.png);
  display: flex;
}

/* もとる  */
#pc_style .dotline-previous-next {
  justify-content: end;
}

/* もとる */
#sp_style .dotline-previous-next {
  justify-content: center;
}
/* previous and next button */
#pc_style .previous-next-button {
  display: flex;
  justify-content: space-between;
}
#sp_style .previous-next-button > div {
  display: flex;
  justify-content: center;
}
#sp_style .btn_wide:before {
  background-size: auto;
}
/* previous and next button green color */
#pc_style .btn_wide.green a,#pc_style .btn_wide.green button {
  background-color: #8fce00;
  border: 1px solid #8fce00; }
#pc_style .btn_wide.green a:hover, #pc_style .btn_wide.green button:hover {
    color: #8fce00;
    background-color: #fff; 
}
/* previous and next button green color */
#sp_style .btn_wide.green a, #sp_style .btn_wide.green button {
  background-color: #8fce00;
  border: 1px solid #8fce00;
}
#sp_style .btn_wide.green a:hover, #sp_style .btn_wide.green button:hover {
    /* color: #8fce00;
  background-color: #fff;  */
  color: #fff;
}
</style>
